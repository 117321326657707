<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 style="color:#00868E">Read more articles</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/read-article1"><img src="../../../../assets/Graphics/Article 1.webp" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/read-article1">The importance of Visitor Management in today's world</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/read-article2"><img src="../../../../assets/Graphics/Article-2.webp" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/read-article2">Benefits of using gatekeepr for your Community</a></h3>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/read-article3"><img src="../../../../assets/Graphics/Article-3.webp" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/read-article3">How gatekeepr streamlines Visitors Management for communities and Business.</a></h3>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
