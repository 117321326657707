<footer class="pb-70">
    <div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center" style="margin-top: 110px;">

                <div class="col-lg-6 footer-images-logo">
                    <a class="navbar-brand" routerLink="/"><img src="../../../../assets/Graphics/Logo.webp"
                            style="width: 100px; height: auto" alt="Logo"></a>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                                <button class="btn newsletter-btn" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">

            <div class="col-sm-6 col-lg-6">
                <div class="footer-item">
                    <div class="footer-quick">

                        <ul class="ul">

                            <li style="display: inline !important;margin-left: 20px; float:left "><a
                                    routerLink="/articles">Articles</a></li>
                            <li style="display: inline !important;margin-left: 35px; float:left "><a
                                    routerLink="/help">Help</a></li>
                            <li style="display: inline !important;margin-left: 35px; float:left"><a
                                    routerLink="/download-app">Download app</a></li>
                            <li style="display: inline !important;margin-left: 35px; float: left;"><a
                                    routerLink="/contact">Contact</a></li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="footer-store">
                    <h3 style="text-align: center;color: white;">Available On</h3>
                    <div class="service-item" style="align-content: end;">
                        <a href="">
                            <img class="apple" style="width:114px"
                                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                                alt="Download on the App Store">
                        </a>
                        <a href=''>
                            <img class="android" style="width: 136px;height: 58px;" alt='Get it on Google Play'
                                src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                        </a>
                    </div>

                </div>
            </div>
            <div class="col-sm-6 col-lg-12">
                <div class="row text-center">
                    <div class="footer-feedback-1">
                        <p class="text-white">© Copyright  {{Date}} Gatekeepr - Visitor Management. - All Rights Reserved. Made proudly by 
                            <a style="color:#fff;" href="https://scrrum-website.web.app/"> Scrrum Labs</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>