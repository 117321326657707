import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';

import { ErrorComponent } from './components/pages/error/error.component';

import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { LoginComponent } from './components/pages/login/login.component';
import { SignupComponent } from './components/pages/signup/signup.component';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';

import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { HelpComponent } from './components/pages/help/help.component';
import { ReviewsComponent } from './components/pages/reviews/reviews.component';
import { ArticlesComponent } from './components/pages/articles/articles.component';
import { DownloadAppComponent } from './components/pages/download-app/download-app.component';
import { ReadArticle1Component } from './components/pages/read-article1/read-article1.component';
import { ReadArticle2Component } from './components/pages/read-article2/read-article2.component';
import { ReadArticle3Component } from './components/pages/read-article3/read-article3.component';


const routes: Routes = [
    {path: '', component: HomeOneComponent},

    {path: 'signup', component: SignupComponent},
    {path: 'login', component: LoginComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-condition', component: TermsConditionComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'features', component: FeaturesComponent},
    {path: 'help', component: HelpComponent},
    {path: 'reviews', component: ReviewsComponent},
    {path: 'articles', component: ArticlesComponent},
    {path: 'download-app', component: DownloadAppComponent},
    {path: 'read-article1', component: ReadArticle1Component},
    {path: 'read-article2', component: ReadArticle2Component},
    {path: 'read-article3', component : ReadArticle3Component},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
