<div class="container">
    <div class="section-title-two">
        <h4  style="color:#00868E">Reviews</h4>
     </div>



<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="testimonial-wrap" style="margin-top: 66px;">
          

            <div class="testimonial-slider owl-theme owl-carousel">
                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R1.webp" alt="Testimonial">
                    <h3   style="color:#00868E">Shivam Badoniya</h3>
                    <p style="color: black;">"Gatekeepr has made our visitor management process so much smoother and more efficient. The calling and verifying feature gives us peace of mind knowing that only authorized visitors are entering our building."</p>
                </div>

                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R3.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Paramveer Singh</h3>
                    <p style="color: black;">"We've been using Gatekeepr for a few months now and have noticed a significant improvement in our building's security. The app is reliable and easy to use, and the calling and verifying feature gives us peace of mind."</p>
                </div>

                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R4.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Kanika Bhatt</h3>
                    <p style="color: black;">"I love the convenience of Gatekeepr's app. The ability to enter my details and call the person I'm visiting all in one place is a real time-saver."</p>
                </div>

                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R9.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Surabhi Verma</h3>
                    <p style="color: black;">"As someone who frequently visits different buildings for work, I really appreciate the consistency and security that Gatekeepr provides. I always feel confident that my identity will be verified before being granted access."</p>
                </div>

                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R10.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Ankita Verma</h3>
                    <p style="color: black;">"The interface of the Gatekeepr app is user-friendly and intuitive. Even visitors who are not tech-savvy can easily navigate the check-in process."</p>
                </div>
                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R2.webp" alt="Testimonial">
                    <h3 style="color:#00868E">Nadim Akhter</h3>
                    <p style="color: black;">"Gatekeepr has made our building more secure than ever before. The calling and verifying feature ensures that only authorized visitors are allowed access, giving us peace of mind."</p>
                </div>
                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R5.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Bhawna Malhotra</h3>
                    <p style="color: black;">"Gatekeepr is an excellent visitor management app that has saved us a lot of time and headaches. The ability to call and verify visitors is a game-changer for our building's security."</p>
                </div>
                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R7.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Abhisek Mittal</h3>
                    <p style="color: black;">"I've used a lot of different visitor management apps in the past, and I have to say that Gatekeepr is the best by far. The app is easy to use and the calling and verifying feature is a huge plus."</p>
                </div>

                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R8.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Vinayak Sharma</h3>
                    <p style="color: black;">"Gatekeepr is an excellent solution for visitor management. The app is fast and efficient, and the calling and verifying feature ensures that only authorized visitors are allowed access."</p>
                </div>
                <div class="testimonial-item">
                    <img src="../../../../assets/Reviews/R6.webp" alt="Testimonial">
                    <h3  style="color:#00868E">Sharmila TL</h3>
                    <p style="color: black;">"Gatekeepr has made our visitor management process much more organized and streamlined. The app is easy to use and the calling and verifying feature is a great way to ensure security."</p>
                </div>
            </div>
        </div>
    </div>
</section>

</div>

