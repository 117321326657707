import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { ContactComponent } from './components/pages/contact/contact.component';
//import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { HelpComponent } from './components/pages/help/help.component';
import { ReviewsComponent } from './components/pages/reviews/reviews.component';
import { ArticlesComponent } from './components/pages/articles/articles.component';
import { DownloadAppComponent } from './components/pages/download-app/download-app.component';
import { ReadArticle1Component } from './components/pages/read-article1/read-article1.component';
import { ReadArticle2Component } from './components/pages/read-article2/read-article2.component';
import { ReadArticle3Component } from './components/pages/read-article3/read-article3.component';
import { AngularFireModule} from '@angular/fire/compat'
import { environment } from 'src/environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';



@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    HomeOneComponent,
//    HomeTwoComponent,
//    HomeThreeComponent,
    // AboutComponent,
//    AppointmentComponent,
//    DepartmentsComponent,
//    TestimonialsComponent,
//    SignupComponent,
//    LoginComponent,
//    PrivacyPolicyComponent,
//    TermsConditionComponent,
//    FaqComponent,
//    ErrorComponent,
//    ServicesComponent,
//    ServicesDetailsComponent,
//    DoctorDetailsComponent,
//    DoctorComponent,
//    BlogComponent,
//    BlogDetailsComponent,
    ContactComponent,
//    ComingSoonComponent,
    FeaturesComponent,
    HelpComponent,
    ReviewsComponent,
    ArticlesComponent,
    DownloadAppComponent,
    ReadArticle1Component,
    ReadArticle2Component,
    ReadArticle3Component,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
