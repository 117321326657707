
<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="../../../../assets/Graphics/Logo.webp"
                style="width: 100px; height: auto" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="../../../../assets/Graphics/Logo.webp"
                        style="width: 100px; height: auto" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent"
                    style="margin-right: 100px;">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/features" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Features</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/reviews" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Reviews</a>

                        </li>

                        <li class="nav-item">
                            <a routerLink="/help" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Help</a>
                        </li>

                    </ul>

                    <div class="nav-srh">
                        <div class="search-toggle">
                            <button class="src-input"><a routerLink="/download-app" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Download
                                    App</a></button>
                        </div>

                    </div>


                </div>
            </nav>
        </div>
    </div>
</div>