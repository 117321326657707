// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    firebase : {
    apiKey: "AIzaSyDdj90lZmp8A44v-QT0vzaJkPFfY_1h1nM",
    authDomain: "gatekeepr-app.firebaseapp.com",
    projectId: "gatekeepr-app",
    storageBucket: "gatekeepr-app.appspot.com",
    messagingSenderId: "689810995142",
    appId: "1:689810995142:web:291c3dd20493f18dca24c7"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
