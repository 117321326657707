
<div class="page-title-area page-title-hight1 h1-m page-title-four gt">
    <div class="d-table">
        <div class="d-table-cell">

            <div class="col-md-6">
            <div class="page-title-item ">
                <h2 >Gatekeepr, stay protected</h2>
                <div class="doctor-btn">
                    <a routerLink="/download-app">Download App</a>
                </div>
            </div>
        </div>
        <div class="col-md-6"></div>
        </div>
    </div>
</div>

<section class="doctors-area dctr-ar ptb-100" >
    <div class="container">

        <!-- <div class="doctor-btn">
            <a routerLink="/download-app">Download App</a>
        </div> -->
        <div class="row second-row-downld">
            <div
                class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
            >
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img
                            src="../../../../assets/Graphics/Group 68.webp"
                            style="width: 300px"
                            alt="Doctor"
                        />
                  
                    </div>

                </div>
            </div>

            <div
                class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
            >
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img
                            src="../../../../assets/Graphics/Group 75 (1).webp"
                            alt="image"
                        />
                     
                    </div>
                </div>
            </div>

            <div
                class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp"
            >
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img
                            src="../../../../assets/Graphics/Group 74 (1).webp"
                            style="width: 300px"
                            alt="Doctor"
                        />
                      
                    </div>

                    <div class="doctor-bottom">
                   
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="footer-store stores-foot">
                <h3 style="text-align: left;color: #222; 
                font-size: 18px;">Available On:</h3>
                <div class="service-item" style="align-content: end;">
                    <a href="">
                        <img class="apple" style="width:114px"
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                            alt="Download on the App Store">
                    </a>
                    <a href=''>
                        <img class="android" style="width: 136px;height: 58px;" alt='Get it on Google Play'
                            src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                    </a>
                </div>

            </div>
        </div>
        <div class="col-md-6"></div>
    </div>
</div>
<div class="container">
    <div class="banner-item">

    </div>
</div>

<section>
    <div class="page-title-area page-title-hight page-title-four">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="page-title-item page-item-1">
                    <h2 >
                        Stay protected and secured with the Gatekeepr app
                    </h2>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
  

    <div class="row" style="justify-content: center; margin-top: 75px">
    
        <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp about-image-new">
            <div class="doctor-item">
                <div class="doctor-top">
                    <img
                        src="../../../../assets/Graphics/About.webp"
                        alt="About-us"
                    />

          
                </div>

             
            </div>
        </div>

    </div>


</div>

<section class="privacy-area pt-100 privacy-areaa">
    <div class="container">
        <div class="privacy-item">
           
            <p >
                This app is typically used by the Residents & security personnel
                at the gate to verify the identity of visitors, and to keep
                track of who is entering and leaving the community. The app can
                also be used to keep track of deliveries, maintenance workers,
                and other visitors. The security personnel can use the app to
                add new visitor request to the residents each time a new visitor
                comes. The app will notify residents. The use of a society
                gatekeeping app can enhance the security and safety of a gated
                community. It can also provide residents with peace of mind,
                knowing that their community is protected by advanced
                technology.
            </p>
        </div>

    </div>
</section>

<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img
                                src="../../../../assets/Graphics/Feature 1.webp"
                                style="width: 530px; margin-right: 145px"
                                alt="Shape"
                            />
                        </div>
                        <h1 class="sign-heading" style="color: #5447ad; margin-top: 160px ">
                            Sign In easy
                        </h1>
                        <p>
                            Sign in with Admin provided credentials for both
                            Gaurds and Residents.
                        </p>

                        <div class="common-btn"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-two">
                            <img
                                src="../../../../assets/Graphics/Feature 2.webp"
                                style="width: 530px; margin-right: 145px"
                                alt="Shape"
                            />
                        </div>
                        <h1 class="sign-headingg" style="color: #5447ad; margin-top: 160px">
                            All visitors list
                        </h1>
                        <p>
                            Get all visitors list on your App home screen and
                            their details when clicked on them.
                        </p>

                        <div class="common-btn"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-three">
                            <img
                                src="../../../../assets/Graphics/Feature 3.webp"
                                style="width: 530px; margin-right: 145px"
                                alt="Shape"
                            />
                        </div>
                        <h1 class="sign-headingg" style="color: #5447ad; margin-top: 160px">
                            Notify on new visitor
                        </h1>
                        <p>
                            When a new visitor reaches to the gate the gaurd
                            will verify and the notification on your device will
                            be sent.
                        </p>

                        <div class="common-btn"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-two">
                            <img
                                src="../../../../assets/Graphics/Cross-verify.webp"
                                style="width: 430px"
                                alt="Shape"
                            />
                        </div>
                        <h1 style="color: #5447ad; margin-top: 160px">
                            Cross verify
                        </h1>
                        <p>
                            One of the key features of Gatekeepr is the ability
                            to call and verify visitors from both sides i.e.,
                            The Residents and Guards. This feature ensures that
                            all visitors are verified and authorized before
                            being allowed access to a building or facility.
                        </p>

                        <div class="common-btn"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">

            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12" style="margin-top: -100px">
                <div class="faq-head">
                    <h2 style="color:#00868E">Help</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="">
                            <a>Get started?</a>
                            <p >
                                This app is typically used by the Residents &
                                security personnel at the gate to verify the
                                identity of visitors, and to keep track of who
                                is entering and leaving the community. The app
                                can also be used to keep track of deliveries,
                                maintenance workers, and other visitors. The
                                security personnel can use the app to add new
                                visitor request to the residents each time a new
                                visitor comes. The app will notify residents.
                                The use of a society gatekeeping app can enhance
                                the security and safety of a gated community. It
                                can also provide residents with peace of mind,
                                knowing that their community is protected by
                                advanced technology.
                            </p>
                        </li>
                        <li class="">
                            <a>Are you resident?</a>
                            <p >
                                On your home screen you can see your latest
                                visitors, as well as for the new visitores you
                                will get an instant notification. The resident
                                can call gaurd for further details or
                                verification or enquiry after which they can
                                allow or deny visitors.
                            </p>
                        </li>
                        <li class="">
                            <a>Are you guard?</a>
                            <p >
                                Gaurds can use the app to add new visitors
                                whenever there is new visitor. They can enter
                                their details like: Name, phone number, purpose
                                of visit, number of visitors, vehicle type and
                                vehilce number after adding details you can send
                                to the corresponding residents. The residents
                                have the option to either allow or deny the
                                visitors.
                            </p>
                        </li>
                        <li class="">
                            <a>How to sign in?</a>
                            <p >
                                You can sign in with the user ID and password
                                provided by admin for both gaurds and residents.
                            </p>
                        </li>
                        <li class="">
                            <a>How it works?</a>
                            <p >
                                Gatekeepr is comprehensive visitor management
                                app that allows for efficient and secure visitor
                                management for organizations. The app is
                                designed to user-friendly and has features to
                                enhance the experience for both gaurds and
                                residents. Gaurds are typically responsible for
                                managing the entry and exit of visitors, while
                                residents are those who are expacting visitors
                                or who need to grant access to their visitors.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 style="color: #00868E">Read more articles</h2>
        </div>

        <div class="row">
            <div
                class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/read-article1"
                            ><img
                                src="../../../../assets/Graphics/Article 1.webp" 
                                alt="Blog"
                        /></a>
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a routerLink="/read-article1"
                                >The importance of Visitor Management in today's
                                world.</a
                            >
                        </h3>
               
                    </div>
                </div>
            </div>

            <div
                class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
            >
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/read-article2"
                            ><img
                                src="../../../../assets/Graphics/Article-2.webp" 
                                alt="Blog"
                        /></a>
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a routerLink="/read-article2"
                                >Benefits of using gatekeepr for your
                                Community.</a
                            >
                        </h3>
              
                    </div>
                </div>
            </div>

            <div
                class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".7s"
            >
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/read-article3"
                            ><img
                                src="../../../../assets/Graphics/Article-3.webp" 
                                alt="Blog"
                        /></a>
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a routerLink="/read-article3"
                                >How gatekeepr streamlines Visitors Management
                                for Business.</a
                            >
                        </h3>
               
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
