import { Component } from '@angular/core';

@Component({
  selector: 'app-read-article2',
  templateUrl: './read-article2.component.html',
  styleUrls: ['./read-article2.component.scss']
})
export class ReadArticle2Component {

}
