import { Component } from '@angular/core';

@Component({
  selector: 'app-read-article1',
  templateUrl: './read-article1.component.html',
  styleUrls: ['./read-article1.component.scss']
})
export class ReadArticle1Component {

}
