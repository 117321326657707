import { Component } from '@angular/core';

@Component({
  selector: 'app-read-article3',
  templateUrl: './read-article3.component.html',
  styleUrls: ['./read-article3.component.scss']
})
export class ReadArticle3Component {

}
