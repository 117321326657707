<div class="container">
<div class="section-title-two">
    <h4  style="color:#00868E">Features</h4>
 </div>

<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img src="../../../../assets/Graphics/Feature 1.webp" style="width: 530px;margin-right:127px;" alt="Shape">
                        </div>
                        <h1 class="sign-heading" style="color:#5447AD;margin-top:160px">Sign In easy</h1>
                        <p style="font-family: lato;">Sign in with Admin provided credentials for both Gaurds and Residents.</p>

                        <div class="common-btn">
                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Graphics/Feature 2.webp" style="width: 530px; margin-right:145px;" alt="Shape">
                        </div>
                        <h1 class="sign-headingg" style="color:#5447AD;margin-top:160px" >All visitors list</h1>
                        <p>Get all visitors list on your App home screen and their details when clicked on them.</p>

                        <div class="common-btn">
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-three">
                            <img src="../../../../assets/Graphics/Feature 3.webp" style="width: 530px; height: auto; margin-right:145px;" alt="Shape">
                        </div>
                        <h1 class="sign-headingg" style="color:#5447AD;margin-top:160px">Notify on new visitor</h1>
                        <p>When a new visitor reaches to the gate the gaurd will verify and the notification on your device will be sent.</p>

                        <div class="common-btn">
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Graphics/Cross-verify.webp" style="width: 530px; margin-right:90px;"   alt="Shape">
                        </div>
                        <h1 style="color:#5447AD;margin-top:160px" >Cross verify</h1>
                        <p>One of the key features of Gatekeepr is the ability to call and verify visitors from both sides i.e., The Residents and Guards. This feature ensures that all visitors are verified and authorized before being allowed access to a building or facility.
                           </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



