
<section class="faq-area faq-help-area pt-100 pb-70">
    <div class="container" style="margin-top: -145px;">
        <div class="row faq-wrap">
            <div class="col-lg-12">

            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2 style="color:#00868E" >Help</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="" >
                            <a>Get started?</a>
                            <p style="font-size: 18px;">This app is typically used by the Residents & security personnel at the gate to verify the identity of visitors, and to keep track of who is entering and leaving the community.
                                The app can also be used to keep track of deliveries, maintenance workers, and other visitors. The security personnel can use the app to add new visitor request to the residents each time a new visitor comes. The app will notify residents.
                                The use of a society gatekeeping app can enhance the security and safety of a gated community. It can also provide residents with peace of mind, knowing that their community is protected by advanced technology..</p>
                        </li>
                        <li class="">
                            <a>Are you resident?</a>
                            <p style="font-size: 18px;">On your home screen you can see your latest visitors, as well as for the new visitores you will get an instant notification. The resident can call gaurd for further details or verification or enquiry after which they can allow or deny visitors.</p>
                        </li>
                        <li class="" >
                            <a>Are you guard</a>
                            <p style="font-size: 18px;">Gaurds can use the app to add new visitors whenever there is new visitor. They can enter their details like: Name, phone number, purpose of visit, number of visitors, vehicle type and vehilce number after adding details you can send to the corresponding residents. The residents have the option to either allow or deny the visitors.</p>
                        </li>
                        <li class="" >
                            <a>How to sign in?</a>
                            <p style="font-size: 18px;">You can sign in with the user ID and password provided by admin for both gaurds and residents.</p>
                        </li>
                        <li class="" >
                            <a>How it works?</a>
                            <p style="font-size: 18px;">Gatekeepr is comprehensive visitor management app that allows for efficient and secure visitor management for organizations. The app is designed to user-friendly and has features to enhance the experience for both gaurds and residents. Gaurds are typically responsible for managing the entry and exit of visitors, while residents are those who are expacting visitors or who need to grant access to their visitors.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</section>
