<section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">
                    <div class="drop-left" style="margin-top: -50px">
                        <h2>Contact us</h2>
                        <p>We would be more happy for your support and suggestions</p>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Name">
                                        <input type="email" name="email" class="form-control" placeholder="Email">
                                        <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Your Message"></textarea>
                                        <button type="submit" class="drop-btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
      
                    <img src="../../../../assets/Graphics/Contact.png" style="width: 400px; height: 400px; margin-bottom: 82px; margin-left: 10px" alt="Contact">
                </div>
            </div>
        </div>

</section>
