<div class="banner-area download-app-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item">
                    <h1>Download the smart and secure way to manage <br> visitors in your organization.</h1>
                    <ul class="list-of-download" style="color: #00868E;font-size: 20px;padding-left: 10%;">
                        <li>Sign in easy </li><br>
                        <li>All visitors list</li><br>
                        <li>Notify for new visitors</li><br>
                        <li>Call the residesnts/Gaurd</li>
                    </ul>

                    <div class="banner-right">
                        <img src="../../../../assets/Graphics/Aoo-download.webp" alt="Banner"
                            style="width: 900pxpx;;height: auto;">

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="container new-dwnld" style="margin-bottom: 118px;">
    <h3 style="text-align: center;">Available On:</h3>
    <div class="service-item">
        <a href="">
            <img class="apple" style="width:160px"
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                alt="Download on the App Store">
        </a>
        <a href=''>
            <img class="android" style="width: 200px;" alt='Get it on Google Play'
                src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
        </a>
    </div>
</div>